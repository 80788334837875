export default {
  state: {
    country: 'RUS'
  },

  mutations: {
    changeLocation(state, country) {
      state.country = country
    }
  },

  getters: {
    location(state) {
      return state.country
    }
  }
}
